import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { formatDistanceToNow } from "date-fns";
import { observer } from "mobx-react-lite";
import Grid from "../components/Grid";

import { AnimatePresence, motion } from "framer-motion";
import {
  IS_TESTNET,
  NANOTOPIA_COLLECTION_NAME,
  VAULT_ACCOUNT,
} from "../lib/config";
import * as atomic from "../lib/atomic";
import {
  getCharacterDeposits,
  getCharacters,
  getCharacterWithdrawals,
  withdrawCharacter,
} from "../lib/api";

const supportedBlockchains = ["wax"];

const CharacterManagement = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [session, setSession] = useState(null);
  const [actionType, setActionType] = useState("Deposit");
  const [characters, setCharacters] = useState([]);
  const [ingameCharacters, setIngameCharacters] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [deposits, setDeposits] = useState([]);

  // to allow changing blockchains etc
  const [blockchain, setBlockchain] = useState("wax");

  const [walletConnected, setWalletConnected] = useState(false);

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchDepositHistory();
    fetchWithdrawHistory();
    fetchIngameCharacters();
  }, [user.isLoggedIn]);

  useEffect(() => {
    restoreSession();
  }, []);

  useEffect(() => {
    if (session && session?.actor) {
      setWalletConnected(true);
      getCharacterAssets();
    }
  }, [session]);

  async function fetchDepositHistory() {
    try {
      let res = await getCharacterDeposits();
      setDeposits(res?.data?.Deposits ?? []);
    } catch (err) {}
  }
  async function fetchWithdrawHistory() {
    try {
      let res = await getCharacterWithdrawals();
      setWithdrawals(res?.data?.Withdrawals ?? []);
    } catch (err) {}
  }

  async function restoreSession() {
    let sess = await user.sessionKit.restore();
    console.log("@ sess", sess);
    if (sess) {
      setSession(sess);
    }
  }

  async function handleConnectWallet() {
    if (blockchain === "wax") {
      let sess = await user.sessionKit.restore();
      if (sess) {
        setSession(sess);
        return;
      }

      const response = await user.sessionKit.login();
      if (response?.session) {
        setSession(response.session);
      }
    }

    // TODO: Others here etc....
  }

  async function logout() {
    await user.sessionKit.logout();
    setSession(null);
    setWalletConnected(false);
  }

  async function handleDisconnectWallet() {
    // TODO: Change logic based on blockchain selected

    logout();
  }

  async function getCharacterAssets() {
    let assets = await atomic.getAssets({
      collectionName: NANOTOPIA_COLLECTION_NAME,
      schemaName: "character",
      owner: session?.actor.toString(),
    });

    console.log("@ character assets", assets);

    setCharacters(assets);
  }

  async function fetchIngameCharacters() {
    try {
      let res = await getCharacters();

      console.log("@ getCharacters res", res.data);

      setIngameCharacters(res?.data?.Characters ?? []);
    } catch (err) {
      console.log("@ err getting characters", err);
    }
  }

  async function handleGoBack() {
    navigate(-1);
  }

  async function handleDeposit(character) {
    console.log("@ handleDeposit");

    if (!character) return;

    try {
      let res = await session.transact(
        {
          action: {
            account: "atomicassets",
            name: "transfer",
            authorization: [session.permissionLevel],
            data: {
              from: session.actor,
              to: VAULT_ACCOUNT,
              asset_ids: [character?.asset_id],
              memo: `character|${user.id}`,
            },
          },
        },
        {
          broadcast: true,
        }
      );

      console.log("@ res", res);

      setCharacters(
        characters.filter(
          (a) => Number(a?.asset_id) !== Number(character?.asset_id)
        )
      );
    } catch (err) {
      console.log("@ err", err);

      // TODO: Handler error
    }
  }

  async function handleWithdraw(character) {
    if (!character) return;

    console.log("@ withdrawing character", character);

    try {
      let res = await withdrawCharacter(character.ID);
      console.log("@ withdraw res", res);

      fetchIngameCharacters();
      fetchWithdrawHistory();
    } catch (err) {
      console.log("@ err", err);
    }
  }

  return (
    <div className="">
      <div
        className="
        relative
        overflow-y-scroll
        block
        z-10
        w-screen 
        h-screen      
      "
        style={{
          background:
            "radial-gradient(45.76% 50% at 50% 50%, rgba(11, 24, 29, 0.20) 0%, rgba(11, 24, 29, 0.90) 92.23%), url('/character-selection/nanotopia-bg-map.png') lightgray 50% / cover no-repeat",
        }}
      >
        {/* Logo/Top Container */}
        <div
          style={{
            background: "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0))",
          }}
          className="flex flex-row justify-between z-30"
        >
          <img
            src="/character-selection/nanotopia-logo.png"
            className="select-none"
          />

          <div className="flex flex-row pr-10 pt-10 items-start">
            {walletConnected && (
              <div className="flex flex-row bg-[#243234] p-2 mr-8 rounded-sm group relative cursor-pointer">
                <img
                  className="object-contain"
                  width={48}
                  src="/character-selection/wax-white-logo.png"
                />
                <p className="mx-2 font-['Inter'] font-semibold text-lg text-white">
                  Logged in as {session?.actor?.toString()}
                </p>

                <div className="flex flex-col absolute bg-transparent left-0 top-full w-full z-50 invisible divide-y divide-gray-100 rounded-lg shadow group-hover:visible">
                  <div className="bg-[#243234] mt-1">
                    <p
                      onClick={handleDisconnectWallet}
                      className="block px-4 py-2 text-white font-['Inter'] font-medium text-center"
                    >
                      Disconnect Wallet
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-row items-center">
              <motion.div
                onClick={handleGoBack}
                whileHover={{ scale: 1.05 }}
                className="flex flex-row items-center cursor-pointer"
              >
                <p className="text-white text-2xl font-['Iceland']">Go Back</p>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center px-14 mb-20">
          <p className="text-white text-4xl font-['Iceland'] mb-10">
            Character Management
          </p>

          <div className="flex flex-row self-stretch">
            {/* Withdraw/Deposit Section */}
            <div
              className="flex flex-[3] flex-col items-center p-5"
              style={{
                borderRadius: 5,
                borderBottom: "1px solid #FFF",
                background: "rgba(219, 219, 219, 0.90)",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                backdropFilter: "blur(5px)",
              }}
            >
              <div className="flex flex-row items-center">
                <p className="mr-10 text-black text-2xl font-['Iceland']">
                  Select Character to:
                </p>
                <div className="flex flex-row">
                  <ActionButton
                    text="Deposit"
                    selected={actionType == "Deposit"}
                    onClick={() => {
                      setActionType("Deposit");
                    }}
                  />
                  <ActionButton
                    text="Withdraw"
                    selected={actionType == "Withdraw"}
                    onClick={() => {
                      setActionType("Withdraw");
                    }}
                  />
                </div>
              </div>

              {/* Blockchain Select Container */}
              <div>
                <div
                  className="flex flex-row items-center justify-evenly px-16 py-5 mt-9"
                  style={{
                    borderRadius: 3,
                    border: "0.2px solid #5F5F5F",
                    background: "#FFF",
                    boxShadow: "0px 2.426px 2.426px 0px rgba(0, 0, 0, 0.25)",
                    backdropFilter: "blur(3.0327868461608887px)",
                  }}
                >
                  <div className="flex flex-col items-center mr-5 cursor-pointer">
                    <img
                      style={{ border: "2px solid rgba(0, 201, 141, 0.79)" }}
                      className="object-cover w-14 h-8 rounded-md px-2 box-border"
                      src="/character-selection/wax-black-logo.webp"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="2"
                      viewBox="0 0 6 2"
                      fill="none"
                      className="mt-2"
                    >
                      <path
                        d="M1 1H5"
                        stroke="#00EFA9"
                        stroke-width="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>

                  <div className="flex flex-col items-center cursor-not-allowed">
                    <p className="border border-gray-600 rounded-sm px-2">
                      Coming Soon
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="2"
                      viewBox="0 0 6 2"
                      fill="none"
                      className="mt-2"
                    >
                      <path
                        d="M1 1H5"
                        stroke="#ACACAC"
                        stroke-width="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Character/Asset Container */}
              {actionType === "Deposit" && (
                <DepositCharacterContainer
                  session={session}
                  characters={characters}
                  onHandleDeposit={handleDeposit}
                  onConnectWallet={handleConnectWallet}
                />
              )}
              {actionType === "Withdraw" && (
                <WithdrawCharacterContainer
                  characters={ingameCharacters}
                  onHandleWithdraw={handleWithdraw}
                />
              )}
            </div>

            <div className="w-4"></div>

            {/* Action History */}
            <div
              className="flex flex-[2] flex-col items-center pt-5"
              style={{
                borderRadius: 5,
                borderBottom: "1px solid #FFF",
                background: "#33484A",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                backdropFilter: "blur(5px)",
              }}
            >
              <div className="flex flex-row items-center justify-between self-stretch px-5">
                <p className="text-white text-3xl font-['Iceland']">
                  {actionType} History
                </p>

                <p
                  className="cursor-pointer text-white text-xl font-['Iceland'] select-none"
                  onClick={() => {
                    if (actionType === "Withdraw") {
                      fetchWithdrawHistory();
                    }
                    if (actionType === "Deposit") {
                      fetchDepositHistory();
                    }
                  }}
                >
                  refresh
                </p>
              </div>

              {actionType === "Deposit" && <Deposits deposits={deposits} />}
              {actionType === "Withdraw" && (
                <Withdrawals withdrawals={withdrawals} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DepositCharacterContainer = ({
  session,
  characters = [],
  onHandleDeposit = () => {},
  onConnectWallet = () => {},
}) => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const isLoggedIn = !!session;

  return (
    <>
      <div
        className="self-stretch mt-9"
        style={{
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.00)",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(23, 64, 52, 0.16) 100%)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {!isLoggedIn && (
          <div className="flex flex-col self-stretch items-center px-5 py-20">
            <p className="font-black text-2xl font-['Iceland'] mb-5">
              Connect your WAX Wallet
            </p>

            <p className="text-black font-medium font-['Inter'] my-5">
              Connect your wallet to deposit your Character(s)
            </p>

            <SvgButton text="Connect" onClick={onConnectWallet} />
          </div>
        )}

        {isLoggedIn && (
          <>
            {characters?.length > 0 && (
              <div className="p-4 grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 xl:grid-cols-2 max-h-[50vh] overflow-y-scroll overflow-x-hidden">
                {characters?.map((character) => {
                  const selected =
                    Number(character?.asset_id) ==
                    Number(selectedCharacter?.asset_id);
                  return (
                    <CharacterCard
                      character={character}
                      selected={selected}
                      onClick={() => {
                        if (selected) {
                          setSelectedCharacter(null);
                        } else {
                          setSelectedCharacter(character);
                        }
                      }}
                    />
                  );
                })}
              </div>
            )}

            {characters?.length === 0 && (
              <div className="flex flex-col items-center self-stretch py-10">
                <p className="text-2xl font-['Iceland'] text-center">
                  No character assets found in your wallet.
                </p>
              </div>
            )}
          </>
        )}
      </div>

      {/* Button Container */}
      <div className="mt-10">
        <SvgButton
          disabled={!selectedCharacter}
          text={"Deposit"}
          onClick={() => {
            onHandleDeposit(selectedCharacter);
          }}
        />
      </div>
    </>
  );
};

const WithdrawCharacterContainer = ({
  characters = [],
  onHandleWithdraw = () => {},
}) => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  return (
    <>
      <div
        className="self-stretch mt-9"
        style={{
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.00)",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(23, 64, 52, 0.16) 100%)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {characters?.length > 0 && (
          <div className="p-4 grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 xl:grid-cols-2 max-h-[50vh] overflow-y-scroll overflow-x-hidden">
            {characters?.map((character) => {
              const selected =
                Number(character?.ID) == Number(selectedCharacter?.ID);

              console.log("@ WITHDRAW character", character);
              return (
                <CharacterCard
                  character={character}
                  selected={selected}
                  onClick={() => {
                    if (selected) {
                      setSelectedCharacter(null);
                    } else {
                      setSelectedCharacter(character);
                    }
                  }}
                />
              );
            })}
          </div>
        )}

        {characters?.length === 0 && (
          <div className="flex flex-col items-center self-stretch py-10">
            <p className="text-2xl font-['Iceland'] text-center">
              No characters found in your account.
            </p>
          </div>
        )}
      </div>

      {/* Button Container */}
      <div className="mt-10">
        <SvgButton
          disabled={!selectedCharacter}
          text={"Withdraw"}
          onClick={() => {
            onHandleWithdraw(selectedCharacter);
          }}
        />
      </div>
    </>
  );
};

const Deposits = ({ deposits = [] }) => {
  return (
    <div className="mt-10">
      <table className="w-full table-fixed">
        <thead>
          <tr className="text-[#6FBF86] border-b border-[#243234] text-sm">
            <th className="py-2 px-2 border-r border-[#243234]">ID</th>
            <th className="py-2 px-2 border-r w-[120px] border-[#243234] whitespace-nowrap">
              Transaction ID
            </th>
            <th className="py-2 px-2 border-r border-[#243234]">From</th>
            <th className="py-2 px-2 border-r border-[#243234]">Status</th>
            <th className="py-2 px-2">Created</th>
          </tr>
        </thead>
        <tbody>
          {deposits.map((deposit) => {
            const baseUrl = IS_TESTNET ? "testnet.waxblock.io" : "waxblock.io";
            const trxUrl = `https://${baseUrl}/transaction/${deposit?.TransactionID}`;
            return (
              <tr
                key={`deposit-${deposit.ID}`}
                className="border-b border-[#243234] text-white"
              >
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  {deposit?.ID}
                </td>
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  <a href={trxUrl} target="_blank">
                    {deposit?.TransactionID}
                  </a>
                </td>
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  {deposit?.From}
                </td>
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  {deposit?.Status}
                </td>
                <td className="py-2 px-2 text-ellipsis overflow-hidden text-center whitespace-nowrap">
                  {formatDistanceToNow(deposit?.CreatedAt, { addSuffix: true })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {deposits?.length === 0 && (
        <p className="text-white text-xl text-center mt-5 font-['Iceland']">
          No deposit history found...
        </p>
      )}
    </div>
  );
};

const Withdrawals = ({ withdrawals = [] }) => {
  return (
    <div className="mt-10">
      <table className="w-full table-fixed">
        <thead>
          <tr className="text-[#6FBF86] border-b border-[#243234] text-sm">
            <th className="py-2 px-2 border-r border-[#243234]">ID</th>
            <th className="py-2 px-2 border-r w-[120px] border-[#243234] whitespace-nowrap">
              Transaction ID
            </th>
            <th className="py-2 px-2 border-r border-[#243234]">To</th>
            <th className="py-2 px-2 border-r border-[#243234]">Status</th>
            <th className="py-2 px-2">Created</th>
          </tr>
        </thead>
        <tbody>
          {withdrawals.map((withdrawal) => {
            const baseUrl = IS_TESTNET ? "testnet.waxblock.io" : "waxblock.io";
            const trxUrl = `https://${baseUrl}/transaction/${withdrawal?.TransferTransactionID}`;
            return (
              <tr
                key={`deposit-${withdrawal.ID}`}
                className="border-b border-[#243234] text-white"
              >
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  {withdrawal?.ID}
                </td>
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  <a href={trxUrl} target="_blank">
                    {withdrawal?.TransferTransactionID}
                  </a>
                </td>
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  {withdrawal?.To}
                </td>
                <td className="py-2 px-2 border-r border-[#243234] text-ellipsis overflow-hidden text-center">
                  {withdrawal?.State}
                </td>
                <td className="py-2 px-2 text-ellipsis overflow-hidden text-center whitespace-nowrap">
                  {formatDistanceToNow(withdrawal?.CreatedAt, {
                    addSuffix: true,
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {withdrawals?.length === 0 && (
        <p className="text-white text-xl text-center mt-5 font-['Iceland']">
          No withdrawal history found...
        </p>
      )}
    </div>
  );
};

const ActionButton = ({ text = "", selected = false, onClick = () => {} }) => {
  const borderColor = selected ? "#00EFA9" : "#5F5F5F";
  const bgColor = selected ? "#D3FFF2" : "#F4F4F4";
  const textColor = selected ? "#303030" : "#979797";
  return (
    <div
      onClick={onClick}
      className={`bg-[${bgColor}] mr-4 px-12 py-2 cursor-pointer`}
      style={{
        backgroundColor: bgColor,
        borderRadius: 3,
        border: `1px solid ${borderColor}`,
        boxShadow: "0px 2.426px 2.426px 0px rgba(0, 0, 0, 0.25)",
        backdropFilter: "blur(3.0327868461608887px)",
      }}
    >
      <p
        style={{ color: textColor }}
        className={`text-[${textColor}] text-2xl font-['Iceland']`}
      >
        {text}
      </p>
    </div>
  );
};

const SvgButton = ({ text = "", disabled = false, onClick = () => {} }) => {
  return (
    <motion.div
      whileHover={disabled ? {} : { scale: 1.05 }}
      className={`relative cursor-pointer ${disabled ? "opacity-70" : ""}`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="179"
        height="45"
        viewBox="0 0 179 45"
        fill="none"
      >
        <path d="M20 0H179L159 45H0L20 0Z" fill="#171717" />
        <circle
          cx="155.5"
          cy="44.5"
          r="5.5"
          fill="url(#paint0_radial_2092_629)"
        />
        <circle
          cx="23.5"
          cy="0.5"
          r="5.5"
          fill="url(#paint1_radial_2092_629)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_2092_629"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(155.359 45) rotate(90) scale(5)"
          >
            <stop offset="0.258154" stop-color="#00FFB4" />
            <stop offset="1" stop-opacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_2092_629"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(23.3588 1) rotate(90) scale(5)"
          >
            <stop offset="0.258154" stop-color="#00FFB4" />
            <stop offset="1" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>

      <div className="absolute top-0 right-0 left-0 bottom-0 flex flex-col items-center justify-center">
        <p className="text-white text-2xl font-['Iceland'] select-none">
          {text}
        </p>
      </div>
    </motion.div>
  );
};

const CharacterCard = ({ character, selected = false, onClick = () => {} }) => {
  let username = "";
  let level = 0;
  let location = "Paragon City";

  // Try get characters username
  if (character?.data?.Username) {
    username = character?.data?.Username;
  }
  if (character?.Username) {
    username = character?.Username;
  }

  // Try get characters level
  if (character?.data?.Level) {
    level = character?.data?.Level;
  }
  if (character?.Level) {
    level = character?.Level;
  }

  // try get characters location
  if (character?.Location?.Name) {
    location = character?.Location?.Name;
  }

  return (
    <motion.div
      onClick={onClick}
      className="flex flex-row cursor-pointer"
      style={
        selected
          ? {
              borderRadius: 2,
              border: "2px solid rgba(0, 239, 169, 1)",
              background:
                "linear-gradient(180deg, rgba(51, 51, 51, 0.70) 0%, rgba(0, 0, 0, 0.70) 80%)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(10px)",
            }
          : {
              borderRadius: 2,
              border: "1px solid rgba(255, 255, 255, 1.00)",
              background:
                "linear-gradient(180deg, rgba(51, 51, 51, 0.50) 0%, rgba(0, 0, 0, 0.50) 80%)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(10px)",
            }
      }
      whileHover={{ scale: 1.05 }}
    >
      {/* Character Details */}
      <div className="flex flex-col flex-1 pl-5 py-5">
        <p className="text-white text-2xl font-['Iceland'] mb-10">{username}</p>
        <p className="text-white text-xl font-['Iceland']">Level {level}</p>
        <p className="text-white text-xl font-['Iceland']">{location}</p>
      </div>

      {/* Character Image */}
      <div className="flex flex-row flex-1">
        <img
          className="object-cover object-right"
          src="/character-selection/anima-black-6.png"
        />
      </div>
    </motion.div>
  );
};

export default observer(CharacterManagement);
