import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

// import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { SessionKit } from "@wharfkit/session";
import { WebRenderer } from "@wharfkit/web-renderer";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";

import { Toaster } from "react-hot-toast";

import user, { UserContext } from "./stores/userStore";
import ui, { UIContext } from "./stores/uiStore";
import chat, { ChatContext } from "./stores/chatStore";

// Routes
import Login from "./routes/Login";
import App from "./routes/App";
import Signup from "./routes/Signup";
import CharacterSelect from "./routes/CharacterSelect";
import AccountLayout from "./layouts/AccountLayout";
import Account from "./routes/Account";
import LinkedWallets from "./routes/LinkedWallets";
import ManageAssets from "./routes/ManageAssets";
import ManageTokens from "./routes/ManageTokens";
import GameLayout from "./layouts/GameLayout";
import CurrentLocation from "./routes/CurrentLocation";
import Profile from "./routes/Profile";
import Character from "./routes/Character";
import Inventory from "./routes/Inventory";
import Bank from "./routes/Bank";
import Waygate from "./routes/Waygate";
import VerifyEmail, {
  ErrorBoundary as VerifyEmailErrorBoundary,
  loader as verifyEmailLoader,
} from "./routes/VerifyEmail";
import ContractKit from "@wharfkit/contract";
import { APIClient } from "@wharfkit/antelope";
import WaygateReceive from "./routes/WaygateReceive";
import { IS_TESTNET } from "./lib/config";
import CharacterImport from "./routes/CharacterImport";
import WaygateSend from "./routes/WaygateSend";
import CharacterSlotAdd from "./routes/CharacterSlotAdd";
import CharacterCreate from "./routes/CharacterCreate";
import CharacterSelection from "./routes/CharacterSelection";
import CharacterCreation from "./routes/CharacterCreation";
import CreateOrImport from "./routes/CreateOrImport";
import CharacterName from "./routes/CharacterName";
import character, { CharacterCreateContext } from "./stores/characterCreate";
import CharacterManagement from "./routes/CharacterManagement";
import Subscription from "./routes/Subscription";

const router = createBrowserRouter([
  // New Proper Design
  {
    path: "/character/select",
    element: <CharacterSelection />,
  },
  {
    path: "/character/create-or-import",
    element: <CreateOrImport />,
  },
  {
    path: "/character/management",
    element: <CharacterManagement />,
  },
  {
    path: "/character/name",
    element: <CharacterName />,
  },
  {
    path: "/character/creation",
    element: <CharacterCreation />,
  },
  {
    path: "/",
    element: <GameLayout />, // TODO: Make this the layout component?
    children: [
      {
        index: true,
        path: "/",
        element: <CurrentLocation />,
      },
      {
        path: "/profile/:id",
        element: <Profile />,
      },
      {
        path: "/character",
        element: <Character />,
      },
      {
        path: "/inventory",
        element: <Inventory />,
      },
      {
        path: "/bank",
        element: <Bank />,
      },
      {
        path: "/waygate",
        element: <Waygate />,
      },
      {
        path: "/waygate/send",
        element: <WaygateSend />,
      },
      {
        path: "/waygate/receive",
        element: <WaygateReceive />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/verify-email/:token",
    element: <VerifyEmail />,
    loader: verifyEmailLoader,
    errorElement: <VerifyEmailErrorBoundary />,
  },
  {
    path: "/",
    element: <AccountLayout />, // TODO: Make this the layout component?
    children: [
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/linked-wallets",
        element: <LinkedWallets />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
      // {
      //   path: "/character-select",
      //   element: <CharacterSelect />,
      // },
      // {
      //   path: "/character-select/create",
      //   element: <CharacterCreate />,
      // },
      // {
      //   path: "/character-select/import",
      //   element: <CharacterImport />,
      // },
      // {
      //   path: "/character-select/add",
      //   element: <CharacterSlotAdd />,
      // },
      // {
      //   path: "/manage-assets",
      //   element: <ManageAssets />,
      // },
      // {
      //   path: "/manage-tokens",
      //   element: <ManageTokens />,
      // },
    ],
  },
]);

const webRenderer = new WebRenderer();

let chains = [];
let walletPlugins = [];
let apiClient;

if (IS_TESTNET) {
  // Testnet
  const url = "https://api.waxtest.alohaeos.com";
  const chainId =
    "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12";

  chains.push({ id: chainId, url });
  apiClient = new APIClient({ url });
  walletPlugins = [new WalletPluginAnchor()];
} else {
  // Mainnet
  const url = "https://api.wax.alohaeos.com";
  const chainId =
    "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4";

  chains.push({ id: chainId, url });
  apiClient = new APIClient({ url });
  walletPlugins = [
    new WalletPluginCloudWallet(),
    new WalletPluginAnchor(),
    new WalletPluginWombat(),
  ];
}

const sessionKit = new SessionKit({
  appName: "Nanotopia",
  chains: chains,
  ui: webRenderer,
  walletPlugins,
});

user.setSessionKit(sessionKit);

const contractKit = new ContractKit({
  client: apiClient,
});

user.setContractKit(contractKit);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UIContext.Provider value={ui}>
      <UserContext.Provider value={user}>
        <ChatContext.Provider value={chat}>
          {/* Just stack these here for now?? */}
          <CharacterCreateContext.Provider value={character}>
            <RouterProvider router={router} />
          </CharacterCreateContext.Provider>
        </ChatContext.Provider>
      </UserContext.Provider>
    </UIContext.Provider>

    <Toaster
      containerStyle={{
        top: 40,
      }}
      toastOptions={{
        className: "font-['Iceland'] text-xl text-white",
        style: {
          background: "#1f2937",
          border: "1px solid #4b5563",
          color: "#fff",
        },
      }}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
