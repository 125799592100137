import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext } from "react";

class ChatStore {
  privateMessageChannels = [];

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "ChatStore",
      properties: ["privateMessageChannels"],
      storage: window.localStorage,
    });
  }

  addChannel(channelName) {
    // limit to 3 open DM's at the moment
    if (this.privateMessageChannels?.length === 3) return;

    this.privateMessageChannels = this.privateMessageChannels.concat([
      channelName,
    ]);
  }

  removeChannel(channelName) {
    this.privateMessageChannels = this.privateMessageChannels.filter(
      (c) => c !== channelName
    );
  }
}

const chat = new ChatStore();

export const ChatContext = createContext();

export default chat;
