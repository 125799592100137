import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../stores/userStore";
import { observer } from "mobx-react-lite";
import Button from "../components/Button";
import toast from "react-hot-toast";
import {
  addFriend,
  getFriendshipStatus,
  removeFriend,
  revokeFriendRequest,
} from "../lib/api";

const Profile = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const user = useContext(UserContext);

  const [friendshipStatus, setFriendshipStatus] = useState({});

  useEffect(() => {
    if (!user.isLoggedIn) {
      navigate("/login");
      return;
    }

    loadProfile();
  }, [user.isLoggedIn]);

  useEffect(() => {
    console.log("@ id", id);
    if (Number(id) > 0) {
      loadFriendshipStatus(id);
    }
  }, []);

  async function loadFriendshipStatus(id) {
    console.log("@ getFriendshipStatus", id);
    try {
      let res = await getFriendshipStatus(id);
      setFriendshipStatus(res?.data ?? {});
    } catch (err) {}
  }

  async function loadProfile() {
    console.log("@ loading profile", id);
    // TODO
  }

  async function handleAddFriend() {
    try {
      let res = await addFriend(id);
      console.log("@ res", res);
      loadFriendshipStatus(id);
    } catch (err) {
      toast.error(err?.response?.data?.Error ?? "unexpected error occured");
    }
  }

  async function handleRevokeFriendRequest() {
    try {
      let res = await revokeFriendRequest(id);
      loadFriendshipStatus(id);
    } catch (err) {
      toast.error(err?.response?.data?.Error ?? "");
    }
  }

  async function handleRemoveFriend() {
    try {
      let res = await removeFriend(id);
      loadFriendshipStatus(id);
    } catch (err) {
      toast.error(err?.response?.data?.Error ?? "");
    }
  }

  function renderFriendButton() {
    console.log("@ rendering friend button", friendshipStatus);

    if (friendshipStatus?.Friendship) {
      return <Button onClick={handleRemoveFriend}>Remove Friend</Button>;
    }

    if (friendshipStatus?.Request) {
      return (
        <Button onClick={handleRevokeFriendRequest}>
          Revoke Friend Request
        </Button>
      );
    }

    return <Button onClick={handleAddFriend}>Add Friend</Button>;
  }

  return (
    <div className="mt-20 px-5">
      <div className="flex flex-row justify-between">
        <h1>Profile Page</h1>
      </div>

      {/* Actions for testing */}
      <div className="p-2">
        {renderFriendButton()}
        <div className="h-1" />
        <Button
          onClick={() => {
            toast.error("TODO: Not implemented");
          }}
        >
          Send Message
        </Button>
        <div className="h-1" />
        <Button
          onClick={() => {
            toast.error("TODO: Not implemented");
          }}
        >
          Send Trade Offer
        </Button>
        <div className="h-1" />
        <Button
          onClick={() => {
            toast.error("TODO: Not implemented");
          }}
        >
          Block Character
        </Button>
        <div className="h-1" />
        <Button
          onClick={() => {
            toast.error("TODO: Not implemented");
          }}
        >
          Report Character
        </Button>
      </div>
    </div>
  );
};

export default observer(Profile);
