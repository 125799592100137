import React, { useEffect } from "react";
import { centrifugeStore } from "../stores/centrifugeStore";
import { WEBSOCKET_API } from "../lib/config";

const CentrifugeProvider = ({ children }) => {
  useEffect(() => {
    // Initialize the Centrifuge client
    centrifugeStore.initializeClient(
      `${WEBSOCKET_API}/chat/connection/websocket`
    );

    // Cleanup on unmount
    return () => centrifugeStore.disconnectClient();
  }, []);

  return <>{children}</>;
};

export default CentrifugeProvider;
