import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext } from "react";
import { jwtDecode } from "jwt-decode";
import { logout, refreshToken } from "../lib/api";

class UserStore {
  id = null;
  token = null;
  refreshToken = null;
  activeCharacter = null;

  characters = [];
  linkedWallets = [];

  sessionKit = null;
  contractKit = null;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "UserStore",
      properties: ["id", "token", "refreshToken", "activeCharacter"],
      storage: window.localStorage,
    });
  }

  setCharacters(val) {
    this.characters = val;
  }

  setLinkedWallets(val) {
    this.linkedWallets = val;
  }

  setToken(token) {
    this.token = token;
    const decoded = jwtDecode(token);
    this.id = decoded.userId;
  }

  setRefreshToken(token) {
    this.refreshToken = token;
  }

  setSessionKit(val) {
    this.sessionKit = val;
  }

  setContractKit(val) {
    this.contractKit = val;
  }

  setActiveCharacter(val) {
    this.activeCharacter = val;
  }

  clear() {
    this.id = null;
    this.token = null;
    this.refreshToken = null;
    this.activeCharacter = null;
  }

  logout = flow(function* () {
    try {
      let res = yield logout();
      this.clear();
    } catch (err) {
      console.log("@ logout err", err);
    }
  });

  refreshAuth = flow(function* () {
    try {
      let res = yield refreshToken({
        refreshToken: this.refreshToken,
      });

      const {
        data: { RefreshToken, Token },
      } = res;

      this.setToken(Token);
      this.setRefreshToken(RefreshToken);
    } catch (err) {
      // @@@ TODO: Show toast saying user has been logged out due to inactivity

      this.clear();
    }
  });

  get tokenExpired() {
    const decoded = jwtDecode(this.token);
    const now = Math.round(Date.now() / 1000);
    return decoded.exp <= now;
  }

  get isLoggedIn() {
    return !!this.token;
  }
}

const user = new UserStore();

export const UserContext = createContext();

export default user;
