import axios from "axios";
import user from "../stores/userStore";
import { GAME_API } from "./config";

export const api = axios.create({
  baseURL: GAME_API,
  timeout: 30000, // 30 seconds
  headers: {},
});

let isRefreshingToken = false;
let refreshSubscribers = [];

// Function to subscribe the requests waiting for token refresh
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// Function to trigger all requests waiting for the token refresh
const onRefreshed = (token) => {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
};

// Request interceptor for API calls
api.interceptors.request.use(
  async (request) => {
    // Only send if we have it
    if (user.token) {
      request.headers["Authorization"] = `Bearer ${user.token}`;
    }

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      error.response?.data?.message === "invalid or expired jwt" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (!isRefreshingToken) {
        isRefreshingToken = true;

        await user.refreshAuth();

        isRefreshingToken = false;
        onRefreshed(); // Notify all subscribers that token is refreshed

        return api(originalRequest);
      } else {
        // If a refresh is already happening, queue the request
        return new Promise((resolve) => {
          subscribeTokenRefresh(() => {
            resolve(api(originalRequest)); // Retry the request after token refresh
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

export async function signup({ email, password }) {
  let res = await api.post("/user/signup", {
    email,
    password,
  });

  console.log("@ signup res", res);
}

export async function verifyEmail({ token }) {
  return await api.post("/user/verify-email", {
    token,
  });
}

export async function login({ email, password }) {
  return await api.post("/user/login", {
    email,
    password,
  });
}

export async function logout() {
  return await api.post(
    "/user/logout",
    {},
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function refreshToken({ refreshToken }) {
  return await api.post("/user/refresh-token", {
    refreshToken,
  });
}

export async function linkWaxWallet(body) {
  return await api.post("/user/link-wallet/wax", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getLinkedWallets() {
  return await api.get("/user/linked-wallets", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function waxLogin(body) {
  return await api.post("/oauth/wax", body);
}

export async function checkUsername(username) {
  return await api.get("/characters/check-username", {
    params: {
      username,
    },
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function createCharacter(body) {
  return await api.post("/characters", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacters() {
  return await api.get("/characters", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacterSlots() {
  return await api.get("/characters/slots", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function enterWorld(body) {
  return await api.post("/characters/enter-world", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacterDeposits() {
  return await api.get("/characters/deposits", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getCharacterWithdrawals() {
  return await api.get("/characters/withdrawals", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getWaygateItems() {
  return await api.get("/waygate/items", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getDeposits() {
  return await api.get("/waygate/deposits", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getWithdrawals() {
  return await api.get("/waygate/withdrawals", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function claimWaygateItems(body) {
  return await api.post("/waygate/claim-items", body, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getInventory() {
  return await api.get("/inventory", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function withdrawItems(items, blockchain = "wax") {
  return await api.post(
    "/waygate/withdraw-items",
    {
      Blockchain: blockchain,
      Items: items,
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function withdrawCharacter(characterId, blockchain = "wax") {
  return await api.post(
    "/characters/withdraw",
    {
      Blockchain: blockchain,
      CharacterID: characterId,
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function addCharacterToSlot(characterId, slotId) {
  return await api.post(
    "/characters/slots/add",
    {
      CharacterID: Number(characterId),
      SlotID: Number(slotId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function removeCharacterFromSlot(characterId, slotId) {
  return await api.post(
    "/characters/slots/remove",
    {
      CharacterID: Number(characterId),
      SlotID: Number(slotId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function searchCharacters(searchText) {
  return await api.get("/search/characters", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    params: {
      search: searchText,
    },
  });
}

export async function addFriend(characterId) {
  return await api.post(
    "/friend/request",
    {
      CharacterID: Number(characterId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function removeFriend(characterId) {
  return await api.post(
    "/friend/remove",
    {
      CharacterID: Number(characterId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function acceptFriendRequest(characterId) {
  return await api.post(
    "/friend/request/accept",
    {
      CharacterID: Number(characterId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function rejectFriendRequest(characterId) {
  return await api.post(
    "/friend/request/reject",
    {
      CharacterID: Number(characterId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function revokeFriendRequest(characterId) {
  return await api.post(
    "/friend/request/revoke",
    {
      CharacterID: Number(characterId),
    },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
}

export async function getFriends() {
  return await api.get("/friend/list", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getFriendRequests() {
  return await api.get("/friend/request/received", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getSentFriendRequests() {
  return await api.get("/friend/request/sent", {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}

export async function getFriendshipStatus(characterId) {
  return await api.get(`/friend/status/${characterId}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
}
