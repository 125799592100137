import { makeAutoObservable } from "mobx";
import { Centrifuge } from "centrifuge";

import userStore from "./userStore";

class CentrifugeStore {
  client = null;
  subscriptions = new Map();
  connected = false;

  constructor() {
    makeAutoObservable(this);
  }

  getClient() {
    return this.client;
  }

  initializeClient(url) {
    if (!this.client) {
      this.client = new Centrifuge(url, {
        getToken: async (ctx) => {
          console.log("@ centrifuge ctx in store", ctx);
          if (userStore.tokenExpired) {
            console.log("@ token is expired, refreshing token");
            await userStore.refreshAuth();
          }
          return userStore.token;
        },
      });

      this.client.on("connected", () => {
        console.log("@@@ Connected to Centrifuge @@@");
        this.connected = true;
      });

      this.client.on("disconnected", (ctx) => {
        console.log("@@@ Disconnected:", ctx);
        this.connected = false;
      });

      console.log("@ CONNECTING CENTRIFUGE @@@@");

      this.client.connect();
    }
  }

  subscribe(channel, onMessage) {
    if (!this.client) {
      console.error("Centrifuge client is not initialized.");
      return null;
    }

    let subscription = this.client.getSubscription(channel);
    if (subscription) {
      console.warn(`Already subscribed to channel: ${channel}`);
      return subscription;
    }

    subscription = this.client.newSubscription(channel);

    subscription.on("publication", (ctx) => onMessage(ctx));
    subscription.on("error", (ctx) =>
      console.error("Subscription error:", ctx)
    );

    subscription.subscribe();

    return subscription;
  }

  unsubscribe(channel) {
    const subscription = this.client.getSubscription(channel);
    if (subscription) {
      subscription.unsubscribe();
      subscription.removeAllListeners();
      this.client.removeSubscription(subscription);
    }
  }

  disconnectClient() {
    if (!this.client) {
      return;
    }

    const subs = this.client.subscriptions();

    // Unsubscribe & Cleanup all subs
    Object.entries(subs).forEach(([key, sub]) => {
      if (sub?.state === "subscribed") {
        sub.unsubscribe();
      }
      sub.removeAllListeners();
      this.client.removeSubscription(sub);
    });

    this.client.disconnect();
    this.client = null;
  }
}

export const centrifugeStore = new CentrifugeStore();
